<template>
	<div>
		<v-sheet class="peronal_task leads-container" style="height: calc(100vh - 88px)">
			<v-layout>
				<v-flex class="py-2">
					<p
						class="my-auto text-uppercase custom-title font-level-3-bold fw-600 ms-0"
						style="margin-top: -4px !important; font-size: 20px !important"
					>
						My Day
						<v-avatar color="indigo" size="30">
							<span class="white--text text-h6">{{ mydaytasklist.length }}</span>
						</v-avatar>
					</p>
				</v-flex>
			</v-layout>
			<v-row class="">
				<v-col cols="9" class="h-100">
					<div
						class="title_section_left"
						style="position: relative; position: relative; height: calc(100vh - 150px)"
					>
						<div class="">
							<h1 class="text-h4 text-capitalize custom-title font-level-3-bold fw-600 ms-0">
								Good Morning, <span class="text-lowercase">johndoe.</span>
							</h1>
							<h1 class="text-h4 text-capitalize font-level-3-bold fw-600 ms-0 grey--text">
								What will you accomplish today?
							</h1>
						</div>
						<div class="d-flex pt-5">
							<div class="month-days text-center mr-4">
								<span class="grey--text font-level-3-bold fw-600">{{ currentDay }}</span>
								<h4 class="text-h2 text-uppercase custom-title font-level-3-bold fw-600 ms-0 mb-0">
									{{ currentDate }}
								</h4>
								<span class="grey--text fw-500">{{ currentMonth }}</span>
							</div>
							<div class="">
								<p class="fw-500">Join video meetings with one tap</p>
								<div class="d-flex align-center">
									<div class="d-flex align-center">
										<v-img
											width="25"
											src="https://th.bing.com/th?id=OSK.6108208517be5f4c6dbcf17a98bce1f6&w=148&h=148&c=7&o=6&pid=SANGAM"
										></v-img>
										<v-btn style="text-transform: capitalize" plain color="blue"
											>Connect Google Calendar</v-btn
										>
									</div>
									<div class="d-flex align-center">
										<v-img
											width="25"
											src="https://th.bing.com/th?id=OSK.6108208517be5f4c6dbcf17a98bce1f6&w=148&h=148&c=7&o=6&pid=SANGAM"
										></v-img>
										<v-btn style="text-transform: capitalize; font-size: 11px" plain color="blue"
											>Connect Outlook Calendar</v-btn
										>
									</div>
								</div>
							</div>
						</div>

						<draggable
							@dragover.prevent="allowDrop"
							group="people"
							class="task_listing"
							v-model="mydaytasklist"
						>
							<v-card
								max-width=""
								v-for="(row, index) in mydaytasklist"
								:key="index"
								class="mx-2 my-5 my_lists cursor-pointer drag-item"
								@click="parentDialog = true"
							>
								<v-card-text>
									<div class="d-flex align-center w-100">
										<v-checkbox
											on-icon="mdi-radiobox-marked"
											off-icon="mdi-radiobox-blank"
											:color="row.taskCheck ? 'grey' : ''"
											@click.stop.prevent="completeElementToLast(index)"
										></v-checkbox>
										<div class="w-100 ms-2">
											<div class="d-flex justify-space-between align-center">
												<div>
													<v-icon small>mdi-account-multiple</v-icon>
													<v-btn
														class="px-0"
														style="text-transform: capitalize; padding: 0px; font-size: 11px"
														plain
														color="darkgrey"
														>Business thrust>{{ row.workspaceTitle }}</v-btn
													>
												</div>
												<div class="list-icon">
													<v-icon
														size="20"
														@click="row.pinClick = !row.pinClick"
														:color="row.pinClick ? 'primary' : ''"
														>mdi-pin-outline</v-icon
													>
													<v-menu offset-y max-width="150">
														<template v-slot:activator="{ on, attrs }">
															<v-icon size="20" v-bind="attrs" v-on="on">mdi-dots-vertical</v-icon>
														</template>
														<v-list>
															<v-list-item v-for="(item, index) in taskItemsList" :key="index">
																<v-list-item-icon class="my-1 mr-2">
																	<v-icon small>{{ item.icon }}</v-icon>
																</v-list-item-icon>
																<v-list-item-title style="text-transform: capitalize !important">{{
																	item.title
																}}</v-list-item-title>
															</v-list-item>
														</v-list>
													</v-menu>
													<v-icon @click.stop.prevent="deleteTaskTitle(row)" size="20">mdi-close</v-icon>
												</div>
											</div>
											<div class="">
												<div>
													<v-tooltip top>
														<template v-slot:activator="{ on, attrs }">
															<v-chip
																v-if="row.onPagelabel != null"
																@click.stop.prevent="hidden = !hidden"
																v-bind="attrs"
																v-on="on"
																style="min-width: 54px; height: 16px !important"
																x-small
																label
																class="mr-2 px-1"
																color="green"
																><span v-show="!hidden" :class="hidden" style="font-size: 12px">{{
																	row.onPagelabel
																}}</span>
															</v-chip>
														</template>
														<span>Color: green, title: "On Page"</span>
													</v-tooltip>
													<v-tooltip top>
														<template v-slot:activator="{ on, attrs }">
															<v-chip
																v-if="row.offPagelabel != null"
																@click.stop.prevent="hidden = true"
																v-bind="attrs"
																v-on="on"
																style="min-width: 54px; height: 16px !important"
																x-small
																label
																color="orange"
																class="px-1"
																><span v-show="!hidden" style="font-size: 12px">
																	{{ row.offPagelabel }}
																</span></v-chip
															>
														</template>
														<span>Color: orange, title: "Off Page"</span>
													</v-tooltip>
												</div>
												<p class="fw-500 mb-0 text-h6" :class="row.taskCheck ? 'grey--text' : ''">
													{{ row.addtaskTitle }}
												</p>
												<div class="d-flex justify-space-between mt-1 flex-wrap">
													<div>
														<v-tooltip bottom>
															<template v-slot:activator="{ on, attrs }">
																<v-btn
																	class="mr-1"
																	v-bind="attrs"
																	v-on="on"
																	depressed
																	style="padding: 0px !important"
																	><v-icon size="16">mdi-clock</v-icon>Sep30</v-btn
																>
															</template>
															<span>This card is due later.</span>
														</v-tooltip>
														<v-tooltip bottom>
															<template v-slot:activator="{ on, attrs }">
																<v-btn
																	plain
																	v-bind="attrs"
																	v-on="on"
																	depressed
																	style="padding: 0px !important; min-width: 20px !important"
																	><v-icon size="16">mdi-playlist-edit</v-icon></v-btn
																>
															</template>
															<span>This card has a description.</span>
														</v-tooltip>
														<v-tooltip bottom>
															<template v-slot:activator="{ on, attrs }">
																<v-btn v-bind="attrs" v-on="on" depressed plain style="padding: 0px !important">
																	<v-icon class="mx-1" size="16">mdi-comment</v-icon>1</v-btn
																>
															</template>
															<span>Comments</span>
														</v-tooltip>
														<v-tooltip bottom>
															<template v-slot:activator="{ on, attrs }">
																<v-btn v-bind="attrs" v-on="on" depressed plain style="padding: 0px !important">
																	<v-icon class="mx-1" size="16">mdi-checkbox-multiple-marked-outline</v-icon>
																	1/4
																</v-btn>
															</template>
															<span>Checklist items</span>
														</v-tooltip>
													</div>
													<div>
														<v-menu
															:nudge-width="250"
															left
															v-model="row.closeMemberMenu"
															offset-y
															:close-on-content-click="false"
														>
															<template v-slot:activator="{ on, attrs }">
																<v-avatar v-bind="attrs" v-on="on" color="blue" size="26">
																	<span class="text-black text-h6 title_card">JD</span>
																</v-avatar>
															</template>
															<v-toolbar color="blue darken-4" dark>
																<v-btn color="cyan" fab dark x-large absolute bottom left>
																	<div class="text-h3 text-black title_card">JD</div>
																</v-btn>
																<div class="pt-3" style="max-width: 80px; margin: 0 auto">
																	<div class="text-h6 title_card">John Doe</div>
																	<div>john@gmail.com</div>
																</div>
																<v-btn
																	absolute
																	top
																	right
																	class="me-1"
																	@click="row.closeMemberMenu = false"
																	small
																	depressed
																	icon
																	><v-icon>mdi-close</v-icon></v-btn
																>
															</v-toolbar>
															<v-list two-line subheader>
																<v-subheader inset> </v-subheader>

																<v-list-item link>
																	<v-list-item-content>
																		<v-list-item-title
																			style="font-weight: 400 !important; text-transform: capitalize !important"
																			class="py-1"
																			>View Profile</v-list-item-title
																		>
																	</v-list-item-content>
																</v-list-item>

																<v-divider class="mx-2"></v-divider>
																<v-list-item link>
																	<v-list-item-content>
																		<v-list-item-title
																			style="font-weight: 400 !important; text-transform: capitalize !important"
																			class="py-1"
																			>Remove from card</v-list-item-title
																		>
																	</v-list-item-content>
																</v-list-item>
															</v-list>
														</v-menu>
													</div>
												</div>
											</div>
										</div>
									</div>
								</v-card-text>
							</v-card>
						</draggable>

						<div class="title_field">
							<v-text-field
								class="rounded add_title"
								hide-details
								:disabled="pageLoading"
								:loading="pageLoading"
								outlined
								placeholder="Add task"
								append-icon="mdi-arrow-up"
								@click:append="addMyTaskTitle"
								@keyup.enter="addTaskTitle"
								dense
								v-model.trim="taskTitle"
							>
								<template v-slot:prepend-inner>
									<v-menu top offset-y>
										<template v-slot:activator="{ on, attrs }">
											<v-icon size="20" color="grey" v-bind="attrs" v-on="on">mdi-text-box-outline</v-icon>
										</template>
										<v-card>
											<v-card-text class="pa-3">
												<v-list class="py-0" flat>
													<div class="d-flex py-2">
														<div class="text-h6 mr-2">My Lists</div>
														<v-icon small>mdi-lock</v-icon>
													</div>
													<v-list-item-group active-class="">
														<template v-for="(item, index) in items">
															<v-divider class="my-0" v-if="index < items.length" :key="index"></v-divider>
															<v-list-item
																@click="setCurrent(item.id)"
																:class="{ active: item.id === currentElement }"
																class="border-0 px-0 py-3"
																:key="item.title"
															>
																<template v-slot:default="{ active }">
																	<v-list-item-content>
																		<v-list-item-title v-text="item.title"></v-list-item-title>
																	</v-list-item-content>

																	<v-list-item-action class="my-0">
																		<v-list-item-action-text v-text="item.action"></v-list-item-action-text>
																		<v-icon color="green">{{ active ? "mdi-check-circle" : "" }}</v-icon>
																	</v-list-item-action>
																</template>
															</v-list-item>
														</template>
													</v-list-item-group>
												</v-list>
											</v-card-text>
										</v-card>
									</v-menu>
								</template>
							</v-text-field>
						</div>
					</div>
				</v-col>
				<v-col cols="3">
					<div class="task_listing_list">
						<v-text-field
							class="rounded add_title mt-0"
							hide-details
							:disabled="pageLoading"
							:loading="pageLoading"
							placeholder="Filter"
							clearable
							outlined
							v-model.trim="filterTask"
						></v-text-field>
						<div class="task_list pt-5">
							<draggable
								group="people"
								class="addtask_listing"
								v-model="mytasklists"
								draggable=".drag-item"
							>
								<v-card
									outlined
									class="mb-5 cursor-pointer drag-item my_lists"
									max-width=""
									v-for="(row, index) in mytasklists"
									:key="index"
									@click="addToTaskListing(row)"
								>
									<v-card-text>
										<div class="d-flex align-center w-100">
											<v-icon color="blue">mdi-plus</v-icon>
											<div class="w-100 ms-2">
												<div class="d-flex justify-space-between align-center">
													<div>
														<v-icon small>mdi-account-multiple</v-icon>
														<v-btn
															class="px-0"
															style="text-transform: capitalize; padding: 0px; font-size: 11px"
															plain
															color="darkgrey"
															>Business thrust>{{ row.workspaceTitle }}</v-btn
														>
													</div>
													<div class="list-icon">
														<v-icon size="20">mdi-dots-vertical</v-icon>
													</div>
												</div>
												<div class="">
													<p class="fw-500 text--primary mb-0 text-h6">{{ row.addtaskTitle }}</p>
													<v-btn
														color="primary"
														plain
														style="text-transform: lowercase; padding-left: 0px !important; font-size: 11px"
														>{{ row.taskDate }}</v-btn
													>
												</div>
											</div>
										</div>
									</v-card-text>
								</v-card>
							</draggable>
						</div>
					</div>
				</v-col>
			</v-row>
			<Dialog :dialog="parentDialog" :dialog-width="900" @close="parentDialog = false">
				<template v-slot:title>
					<div style="width: 100%" class="d-flex align-center justify-space-between task-dialog">
						<v-btn-toggle
							v-model="priority_status"
							tile
							group
							divided
							colo
							blue
							variant="plain"
							mandatory
						>
							<v-btn color="red" height="34" variant="flat" style="border: 2px solid #c42f22"> Low </v-btn>

							<v-btn color="green" variant="flat" style="border: 2px solid rgb(34, 167, 93)" height="34">
								Medium
							</v-btn>
							<v-btn color="blue" variant="flat" style="border: 2px solid #2196f3" height="34">
								Heigh
							</v-btn>
						</v-btn-toggle>
						<v-spacer></v-spacer>
						<v-tooltip top style="z-index: 999999 !important; position: relative !important">
							<template v-slot:activator="{ on, attrs }">
								<v-btn
									v-bind="attrs"
									v-on="on"
									@click="myDayEvent = !myDayEvent"
									color="primary"
									small
									icon
									class="mr-2"
								>
									<v-icon>mdi-bullseye</v-icon>
								</v-btn>
							</template>
							<span style="z-index: 999999 !important; position: relative">Add to My Day</span>
						</v-tooltip>
						<DueDateStatus></DueDateStatus>
						<v-btn @click="parentDialog = false" icon large>
							<v-icon>mdi-close</v-icon>
						</v-btn>
					</div>
				</template>
				<template v-slot:body>
					<TaskAddItemDialog></TaskAddItemDialog>
				</template>
			</Dialog>
		</v-sheet>
	</div>
</template>
<script>
import draggable from "vuedraggable";
import Dialog from "@/view/components/Dialog";
import TaskAddItemDialog from "@/view/components/TaskAddItemDialog";
import DueDateStatus from "@/view/components/DueDateStatus";
export default {
	name: "task-listing",
	data() {
		return {
			draggedIndex: null,
			pageLoading: false,
			//taskCheck: false,
			taskTitle: "",
			pinClick: false,
			parentDialog: false,
			priority_status: [0],
			myDayEvent: false,
			hidden: false,
			filterTask: "",
			currentDay: "",
			currentDate: "",
			currentMonth: "",
			currentElement: null,
			items: [
				{
					id: 1,
					title: "Personal",
				},
				{
					id: 2,
					title: "Work",
				},
				{
					id: 3,
					title: "To do",
				},
				{
					id: 4,
					title: "List",
				},
			],
			taskItemsList: [
				{ icon: "mdi-bell-outline", title: "Reminder" },
				{ icon: "mdi-note-text", title: "Lists" },
				{ icon: "mdi-pound", title: "Tags" },
				{ icon: "mdi-pin", title: "Pin" },
			],
			mydaytasklist: [
				{
					pinClick: false,
					workspaceTitle: "To Do",
					addtaskTitle: "task title",
					offPagelabel: "Off Page",
					onPagelabel: "On Page",
					taskCheck: false,
					closeMemberMenu: false,
				},
			],
			mytasklists: [
				{
					id: 1,
					workspaceTitle: "To Do",
					addtaskTitle: "task title 1",
					statusColor: "yellow",
					taskDate: "From 1 days ago",
				},
				{
					id: 2,
					workspaceTitle: "In progress",
					addtaskTitle: "task title 2",
					statusColor: "red",
					taskDate: "From 2 days ago",
				},
				{
					id: 3,
					workspaceTitle: "Completed",
					addtaskTitle: "task title 3",
					statusColor: "yellow",
					taskDate: "From 3 days ago",
				},
				{
					id: 4,
					workspaceTitle: "On-Hold",
					addtaskTitle: "task title 4",
					statusColor: "red",
					taskDate: "From 5 days ago",
				},
				{
					id: 5,
					workspaceTitle: "Cancel",
					addtaskTitle: "task title 5",
					statusColor: "yellow",
					taskDate: "From 4 days ago",
				},
				{
					id: 6,
					workspaceTitle: "Cancel",
					addtaskTitle: "task title 6",
					statusColor: "yellow",
					taskDate: "From 8 days ago",
				},
			],
		};
	},
	methods: {
		setCurrent(i) {
			this.currentElement = i;
		},
		completeElementToLast(index) {
			// Ensure that the index is within valid bounds
			if (index >= 0 && index < this.mydaytasklist.length) {
				// Remove the element from its current position
				const removedElement = this.mydaytasklist.splice(index, 1)[0];
				removedElement.taskCheck = true;
				// Push the element to the end of the array
				this.mydaytasklist.push(removedElement);
			}
		},
		addMyTaskTitle() {
			if (this.taskTitle) {
				this.mydaytasklist.unshift({
					pinClick: false,
					addtaskTitle: this.taskTitle,
				});
			}
			(this.taskTitle = ""), console.log("Adding task title:", this.taskTitle);
		},
		deleteTaskTitle(row) {
			const indexToRemove = this.mydaytasklist.indexOf(row);
			if (indexToRemove > -1) {
				// Remove from mydaytasklist
				this.mydaytasklist.splice(indexToRemove, 1);
				// Add to mytasklists
				this.mytasklists.push({
					pinClick: false,
					addtaskTitle: row.addtaskTitle,
					offPagelabel: "Off Page",
					onPagelabel: "On Page",
					workspaceTitle: row.workspaceTitle,
				});
			}
		},
		addToTaskListing(row) {
			// Assuming row is the item you want to add
			const indexToRemove = this.mytasklists.indexOf(row);
			if (indexToRemove > -1) {
				// Remove from mytasklists
				this.mytasklists.splice(indexToRemove, 1);
				// Add to mydaytasklist
				this.mydaytasklist.unshift({
					pinClick: false,
					addtaskTitle: row.addtaskTitle,
					workspaceTitle: row.workspaceTitle,
				});
			}
		},
	},
	mounted() {
		const d = new Date();
		this.currentDate = d.getDate();
		const months = [
			"January",
			"February",
			"March",
			"April",
			"May",
			"June",
			"July",
			"August",
			"September",
			"October",
			"November",
			"December",
		];
		this.currentMonth = months[d.getMonth()];
		const days = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
		this.currentDay = days[d.getDay()];
	},
	components: {
		draggable,
		TaskAddItemDialog,
		Dialog,
		DueDateStatus,
	},
};
</script>
<style scoped>
.peronal_task {
	font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Noto Sans, Ubuntu, Droid Sans,
		Helvetica Neue, sans-serif !important;
	font-size: 14px !important;
	font-weight: 400 !important;
	line-height: 20px;
}
.title_section_left {
	max-width: 60%;
	margin: 0 auto;
}
/* .title_section_left h1{
	font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Noto Sans, Ubuntu, Droid Sans,
		Helvetica Neue, sans-serif !important;
} */
.my_lists {
	background-color: #fff;
	box-shadow: 0 1px 4px rgba(0, 0, 0, 0.02), 0 2px 18px rgba(0, 0, 0, 0.05) !important;
}
.my_lists.overlay-card {
	position: relative;
}
.my_lists.overlay-card::before {
	position: absolute;
	content: "";
	top: 0;
	bottom: 0;
	right: 0;
	left: 0;
	height: 100%;
	background: #000 !important;
	opacity: 0.2;
}
.task_listing {
	height: calc(100vh - 330px);
	overflow: auto;
}
.addtask_listing {
	height: calc(100vh - 200px);
	overflow: auto;
	padding-right: 5px;
}
.list-icon {
	display: none;
}
.my_lists:hover {
	background-color: #f7f7f7;
}
.my_lists:hover .list-icon {
	display: initial;
}
.title_field {
	position: absolute;
	bottom: 0px;
	width: 100%;
	background-color: #fff;
	padding: 4px;
}
.task-dialog {
	font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Noto Sans, Ubuntu, Droid Sans,
		Helvetica Neue, sans-serif !important;
	font-size: 14px;
	font-weight: 400 !important;
	line-height: 20px;
}
.task-dialog
	button.v-btn.v-item--active.v-btn--active.v-btn--is-elevated.v-btn--has-bg.theme--light.v-size--default.red {
	background-color: #c42f22 !important;
	color: #fff;
}
.task-dialog
	button.v-btn.v-item--active.v-btn--active.v-btn--is-elevated.v-btn--has-bg.theme--light.v-size--default.green {
	background-color: rgb(34, 167, 93) !important;
	color: #fff;
}
.task-dialog
	button.v-btn.v-item--active.v-btn--active.v-btn--is-elevated.v-btn--has-bg.theme--light.v-size--default.blue {
	background-color: rgb(33, 150, 243) !important;
	color: #fff;
}
.sortable-chosen {
	border: 1px dashed darkgray;
}
.sortable-ghost .v-card__text {
	opacity: 0;
}
</style>
